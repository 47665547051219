import React, { useState, useEffect } from 'react';
import './App.css';
import axios from 'axios';
import PlayerTable from './components/playerTable/PlayerTable';
import ResponsiveAppBar from './components/appbar/ResponsiveAppBar'; // Import the 'ResponsiveAppBar' component

function App() {
  const [players, setPlayers] = useState([]);
  useEffect(() => {
    // Replace 'your-api-endpoint' with the actual API endpoint
    axios.get('https://rsh.audicted.es/riotapi/v2/soloq/summoners/info')
      .then(response => setPlayers(response.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);
  const [playersFlex, setPlayersFlex] = useState([]);
  useEffect(() => {
    // Replace 'your-api-endpoint' with the actual API endpoint
    axios.get('https://rsh.audicted.es/riotapi/v2/flexq/summoners/info')
      .then(response => setPlayersFlex(response.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);
  return (
    <div className='body'>
      <ResponsiveAppBar/>
      <div className='rank'>
        <div className='box'>
          <h1 className='Title'>SoloQ ranking</h1>
          <div className='table'>
            <PlayerTable players={players} />
          </div>
        </div>
        <div className='box'>
          <h1 className='Title'>FlexQ ranking</h1>
          <div className='table'>
            <PlayerTable players={playersFlex} />
          </div>
        </div>
      </div>
      
    </div>
     
  );
}

export default App;
